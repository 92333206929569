import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import Forarprov from '../../images/utbildningar/forarprov.jpg'
const BilProv = () => (
  <Layout>
    <Seo title='Uppkörningen' />
    <PageHeader text='Lån av trafikskolans fordon på prov' />
    <div className='container'>
      <div className='row j'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          <h2>Förarprov "Uppkörningen"</h2>
          <p>När dagen är kommen för förarprov "uppkörning" så utgår eleven och läraren från oss på trafikskolan.
            Detta gäller alla våra förarprov som är bokade av trafikskolans receptionister.
            Lån av trafikskolans fordon på prov inkluderar alltid 50 minuters körning (uppvärmning) innan själva uppköringen.
            Läraren väntar på eleven under tiden alt följer med under provtillfället.
            Efter avslutad uppkörning kör läraren tillbaka eleven till trafikskolan
          </p>

          <p>När du gör ditt körprov ska du visa att du självständigt kan
            tillämpa dina kunskaper i praktiken för att vara en trafiksäker
            förare.
          </p>
          <h4>De kompetensområden som bedöms är: </h4>
          <h6 className='mb-0'>Fordonskännedom</h6>
          <ul>
            <li className='mb-0'>Du ska kunna göra en säkerhetskontroll på bilen, till exempel
              kontroll av belysning, däck, motorolja, och förstå vilka risker
              som kan uppstå om bilen inte fungerar som den ska.
            </li>
          </ul>

          <h6 className='mb-0'>Manövrering</h6>
          <ul>
            <li className='mb-0'>Du ska kunna manövrera bilen, till exempel använda pedalerna
              på rätt sätt.
            </li>
            <li className='mb-0'>Du ska kunna styra bilen, både när du kör rakt fram och när du
              svänger.
            </li>
            <li className='mb-0'>  Du ska kunna backa och parkera på ett naturligt sätt.
            </li>
            <li className='mb-0'>  Du ska kunna ändra på reglage medan du kör, till exempel
              höja eller sänka värmen och använda vindrutetorkare.
            </li>
          </ul>

          <h6 className='mb-0'>Miljömedveten körning</h6>
          <ul>
            <li className='mb-0'>Du ska kunna köra fordonet med god planering och framförhållning
            </li>
            <li className='mb-0'>Du ska kunna välja den växel som är mest lämplig för miljön
              och trafiksäkerheten.
            </li>
          </ul>

          <h6 className='mb-0'>Trafikregler</h6>
          <ul>
            <li className='mb-0'>Du ska kunna trafikreglerna och följa dem på ett naturligt sätt
              så att samspelet med andra trafikanter fungerar
            </li>
          </ul>

          <h6 className='mb-0'>Trafiksäkerhet och beteende</h6>
          <ul>
            <li className='mb-0'>Du ska vara uppmärksam när du kör och anpassa din körning
              efter det du ser.
            </li>
            <li className='mb-0'>Du ska veta var, när och hur du ska titta i olika situationer.
            </li>
            <li className='mb-0'> Du ska kunna identifiera och förstå vilka risker som finns i
              trafiken och kunna planera din körning så att du är beredd i
              situationer som kräver det.
            </li>
            <li className='mb-0'> Du ska anpassa din hastighet efter sikt, väglag och situationer.
            </li>
            <li className='mb-0'> Du ska placera bilen tydligt på vägen, till exempel när du ska
              svänga eller välja körfält.
            </li>
            <li className='mb-0'> Du ska köra med säkerhetsmarginaler, till exempel veta
              hur du anpassar avståndet till andra trafikanter och stillastående fordon.
            </li>
            <li className='mb-0'>Du ska tydligt och i god tid visa vart du ska genom att använda
              blinkers.
            </li>
          </ul>

          <h4> Ordinarie pris till trafikskolan: 1600 kr</h4>
          <ul>
            <li className='mb-0'>då ingår 50 minuters uppvärmning</li>
            <li className='mb-0'>lån av bil under provtillfället</li>
          </ul>

          <h4>Avgifter till Trafikverket: 800 kr</h4>
          <ul>
            <li className='mb-0'>Betalas separat via bekräftelsebrev</li>
            <li className='mb-0'>Eller genom att logga in på "Mina sidor" på Trafikverket hemsida</li>
          </ul>

          <h4 className='mb-0'>Observera</h4>
          <p>
            Teoriprovet utgår aldrig  ifrån trafikskolan.
            Vid teoriprov hos trafikverket är det viktigt att eleven själv infinner sig på rätt tid och plats.
            Se bokningsbekräftelse som skickas hem med post
          </p>
        </div>

        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-center'>
          <img src={Forarprov} alt='förarprov' className='img-fluid rounded' />
          <p className='mb-1 text-sm-center'>
            <a href='https://www.trafikverket.se/korkort/korkortsprov/personbil-och-latt-lastbil/korprov-b/' className='btn btn-danger' target='_Blank' rel='noreferrer'>Läs mera om förarprovet här</a>
          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default BilProv
